.bg-login {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background-image: url(../../../public/bg-login.png);
  background-size: cover;
  background-position: center;
}

@media (min-width: 576px) {
  .bg-login {
    height: 100vh;
  }
}