input[type="checkbox"] {
  height: 0;
  width: 0;
  visibility: hidden;
}

label.switch {
  cursor: pointer;
  text-indent: -9999px;
  width: 40px;
  height: 16px;
  background: #eaeae9;
  display: block;
  border-radius: 100px;
  position: relative;
}

label.switch:after {
  content: "";
  position: absolute;
  top: -4px;
  left: -4px;
  width: 24px;
  height: 24px;
  background: #afafaf;
  border-radius: 90px;
  transition: 0.3s;
}

input:checked + label.switch {
  background: #eaeae9;
}

input:checked + label.switch:after {
  left: calc(100% + 4px);
  transform: translateX(-100%);
  background: linear-gradient(270deg, #f47c49 0%, #f5a623 100%);
}

label.switch:active:after {
  width: 50px;
}

.switch-text {
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
}

@media (min-width: 576px) {
  .switch-text {
    font-size: 18px;
    line-height: 28px;
  }
}
