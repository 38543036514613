.box-login {
  width: 312px;
  padding: 24px 16px;
  margin-top: 50px;
}

@media (min-width: 576px) {
  .box-login {
    width: 478px;
    padding: 42px 32px;
    margin-top: 80px;
  }
}