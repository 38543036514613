.fc-icon-chevron-left:before,
.fc-icon-chevron-right:before {
  color: #7b7c7c;
}

.fc .fc-button-primary,
.fc .fc-button-primary:disabled {
  background-color: white;
  border-color: #eaeae9;
  color: #7b7c7c;
}

.fc .fc-button-primary:not(:disabled).fc-button-active {
  background-color: #11cbe1;
  border-color: #eaeae9;
  outline: none;
  box-shadow: none;
}

.fc .fc-button-group > .fc-button:focus {
  outline: none;
  box-shadow: none;
}

.fc .fc-button-group > .fc-button:hover,
.fc .fc-button-primary:hover {
  background-color: #11cbe1;
  border-color: #eaeae9;
  color: #fff;
}

.fc-v-event {
  border: none;
  background-color: transparent;
}

.fc .fc-toolbar-title {
  font-weight: 500;
}

.fc-daygrid-dot-event {
  justify-content: center;
}
.fc .fc-button {
  padding: 6px;
  font-size: 0.75rem;
}

.fc-daygrid-event {
  white-space: nowrap;
}

a {
  color: #333333;
  text-decoration: none;
  cursor: pointer;
}

.fc .fc-daygrid-day-number {
  padding: 16px;
}

.fc .fc-toolbar-title {
  font-size: 0.75rem;
}

.fc .fc-popover {
  z-index: 1000;
  max-height: 450px;
  overflow: scroll;
}

.fc .fc-scroller-liquid-absolute {
  right: -13px;
}

.fc .fc-daygrid-body-unbalanced .fc-daygrid-day-events {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  overflow: hidden;
  margin: 0 4px;
}

.fc .fc-timegrid-axis-cushion {
  display: none;
}

.fc .fc-timegrid-col-frame {
  overflow: hidden;
}

.fc-timegrid-event-harness > .fc-timegrid-event {
  white-space: nowrap;
}

.fc-direction-ltr .fc-daygrid-event.fc-event-start, .fc-direction-rtl .fc-daygrid-event.fc-event-end {
  margin: 0;
  width: 100%;
  max-width: 140px;
}

.fc-h-event {
  background-color: transparent !important;
  border: none !important;
}

.title-cal {
  font-size: 0.75rem;
  color: white;
  font-weight: 500;
  line-height: 18px;
}

.text-regular {
  font-size: 0.625rem;
  color: white;
}

@media (min-width: 576px) {
  .fc .fc-toolbar-title {
    font-size: 1.25rem;
  }

  .fc .fc-button {
    font-size: 1em;
  }
}