* {
  font-family: 'Rubik', sans-serif;
}

.text-dark {
  color: #333 !important;
}

.text-blue {
  color: #11cbe1 !important;
}

.text-grey {
  color: #7b7c7c !important;
}

.text-orange {
  color: #f5a623 !important;
}

.text-soft {
  color: #afafaf;
}

.text {
  font-weight: 400 !important;
  font-size: 12px !important;
  line-height: 18px !important;
}

.text-bigger {
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 22px !important;
}

.text-medium {
  font-weight: 500 !important;
}

.text-nav {
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 22px !important;
  color: #333 !important;
  padding: 7px 24px !important;
}

.text-nav.active {
  font-weight: 500 !important;
  color: #11cbe1 !important;
  background: #ecfbfc !important;
  border-radius: 6px !important;
}

.dropdown-toggle::after {
  display: none !important;
}

.bg-grey {
  background-color: #f4f4f4;
}

.bg-blue {
  background-color: #11cbe1;
}

.bg-soft-blue {
  background: #ECFBFC;
}

.title {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}

.subtitle {
  font-size: 12px;
  line-height: 18px;
}

.btn-custom {
  background: linear-gradient(270deg, #f47c49 0%, #f5a623 100%);
  border-radius: 6px !important;
  padding: 10px 16px !important;
  border: none !important;
}

.btn-danger {
  background: #ff5d5d;
  border-radius: 6px !important;
  padding: 10px 16px !important;
  border: none !important;
}

.btn-border {
  background-color: transparent !important;
  border: 1px solid #f5a623 !important;
  border-radius: 6px !important;
  padding: 11px 12px !important;
}

.btn-custom:focus {
  outline: none !important;
  box-shadow: none !important;
}

.navbar-toggler:focus {
  box-shadow: none !important;
}

@media (min-width: 576px) {
  .title {
    font-size: 24px;
    line-height: 34px;
  }

  .subtitle {
    font-size: 16px;
    line-height: 28px;
  }

  .text {
    font-size: 14px !important;
    line-height: 22px !important;
  }

  .text-bigger {
    font-size: 16px !important;
    line-height: 24px !important;
  }
}
