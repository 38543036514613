.text-title {
  font-size: 14px;
line-height: 22px;
  color: #474544;
}

.text-section {
  font-size: 14px;
  line-height: 22px;
}

.form-check-input:checked {
  background-color: rgb(17, 203, 225) !important;
  border-color: rgb(17, 203, 225) !important;
}

.img-icon {
  width: 32px;
  height: 32px;
}

/* .my-radio {
  border: 1px solid #eaeae9;
  padding: 16px;
}

.my-radio .form-check-label {
  margin-left: 32px;
  margin-top: 3px;
}

.my-radio input[type="radio"] {
  -webkit-appearance: none;
  width: 20px;
  height: 20px;
  margin-left: 0;
  border: 2px solid #d5d8d8;
  border-radius: 50%;
  outline: none;
}

.my-radio input[type="radio"]:before {
  content: "";
  display: block;
  width: 60%;
  height: 60%;
  margin: 20% auto;
  border-radius: 50%;
}

.my-radio input[type="radio"]:checked:before {
  background: #11cbe1;
}

.my-radio input[type="radio"]:after {
  content: "";
  display: block;
  width: 20px;
  height: 20px;
  margin: -18px -2px;
  border-radius: 50%;
}

.my-radio input[type="radio"]:checked:after {
  border: 2px solid #11cbe1;
} */

@media (min-width: 576px) {
  .img-icon {
    width: 60px;
    height: 60px;
  }

  .text-title {
    font-size: 18px;
    line-height: 28px;
  }
}
